<script setup lang="ts">
const props = defineProps<{
  text1?: any
  text2?: any
  text3?: any
  text4?: any
  code?: any
  totalPayment?: any
  currencyKey?: any
  codes?: any
  isbalance?: any
  iscontinue?: any
  data?: any
  currency?: any
  multi?: any
}>()

const { t, n } = useI18n()
</script>

<template>
  <div class="mx-auto text-center max-w-4xl px-4 py-6 sm:px-8 sm:py-10 rounded-lg border">
    <div class="flex flex-col text-center items-center">
      <div class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-5 flex-shrink-0">
        <svg class="w-16 h-16" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd" />
        </svg>
      </div>
      <div class="flex-grow">
        <div class="text-gray-900 text-3xl sm:text-4xl title-font font-bold mb-5">
          {{ props?.text1 ?? t('payment_done') }}
        </div>
        <div class="leading-relaxed text-2xl sm:text-xl mb-5">
          {{ props?.text2 ?? t('payment_thanks') }}
        </div>
        <div v-if="props?.code" class="mb-5 text-xl">
          {{ props?.text3 ?? t('order_code') }}:
          <span class="font-bold ml-1 uppercase">
            {{ props?.code }}
          </span>
        </div>
        <div v-if="checkValue(props?.totalPayment)" class="mb-5 text-xl">
          {{ props?.text4 ?? t('total_payment') }}:
          <span class="font-bold ml-1">
            {{ n((props?.totalPayment ?? 0) * 1, props?.currencyKey ? 'currency' : 'decimal', props?.currencyKey) }}
          </span>
          <span v-if="props?.multi && props?.data" class="ml-1">
            {{ `${t('of')} ${props?.data?.length} ${t('orders')}` }}
          </span>
        </div>

        <div v-if="props?.codes?.length" class="mb-5">
          <div
            v-for="(item, index) in props?.codes"
            :key="`gAXcxB${index}`"
            class="border-b py-5"
          >
            <OrderResultViewItem :data="{ id: item }" :nopayment="true" />
          </div>
        </div>

        <div v-if="props?.data && props?.isbalance" class="mb-5">
          <div v-if="props?.multi && props?.data?.length">
            <div
              v-for="(item, index) in props?.data"
              :key="`yvQqYA${index}`"
              class="border-b py-5"
            >
              <OrderResultViewBalanceItem :data="item" :currency="props?.currency" />
            </div>
          </div>
          <OrderResultViewBalanceItem v-else :data="props?.data" :currency="props?.currency" />
        </div>

        <div v-else-if="props?.data" class="mb-5">
          <div v-if="props?.multi && props?.data?.length">
            <div
              v-for="(item, index) in props?.data"
              :key="`vzfFGF${index}`"
              class="border-b py-5"
            >
              <OrderResultViewItem :data="item" :currency="props?.currency" />
            </div>
          </div>
          <OrderResultViewItem v-else :data="props?.data" :currency="props?.currency" />
        </div>

        <ButtonToHome v-if="props?.iscontinue" class="mb-5" />
      </div>
    </div>
  </div>
</template>
